export default ({ store, app }, inject) => {
  const submitJoinCommunity = async (communityID) => {
    return await app.$axios.post(process.env.BASE_API + "communities/member", {
      id_community: communityID,
    });
  };

  const getQuestions = async (
    communityID,
    questionPage = 1,
    questionLimit = 10
  ) => {
    const limit = `&p=${questionPage ?? ""}`;
    const page = `limit=${questionLimit ?? ""}`;
    try {
      const response = await app.$axios.get(
        process.env.BASE_API +
          "view/communities/" +
          communityID +
          "/questions" +
          `?${page + limit}`
      );
      return response.data.body;
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handling Trending in Community Discussions
   *
   * @param {*} page
   * @returns Promise<Content>
   */
  const getPopularDiscussions = async (page = null) => {
    const params = page ? `?content=${page}` : "";
    try {
      const res = await app.$axios.get(
        process.env.BASE_API + "view/communities/questions/popular" + params
      );
      return res.data.body;
    } catch (error) {
      throw error;
    }
  };

  // =========================================
  // Community API Helpers
  // =========================================

  /** 
    Handling communities getter
    
    @param {number} limit
    @param {number} page
    @param {string} name
    @param {boolean} notJoinOnly
  **/

  const getCommunities = async (
    limit = 10,
    page = 1,
    name = "",
    notJoinOnly = false
  ) => {
    const params = `?sort=created_at&order=DESC&limit=${limit}&p=${page}`;
    const addOnParams = name ? `&name=${name}` : "";
    // const addOnParams = notJoinOnly ? "&is_new=1" : "";
    try {
      const response = await app.$axios.get(
        `${process.env.BASE_API}view/communities${params}` +
          addOnParams +
          (notJoinOnly ? "&is_new=1" : "")
      );
      return response.data.body;
    } catch (error) {
      throw error;
    }
  };

  /** 
      Handling recommended communities getter
      
      @param {number} limit
      @param {number} page
    **/

  const getRecommendedCommunities = async (limit = 10, page = 1) => {
    const params = `?sort=created_at&order=DESC&limit=${limit}&p=${page}`;
    try {
      const response = await app.$axios.get(
        `${process.env.BASE_API}view/communities/recommended${params}`
      );
      return response.data.body;
    } catch (error) {
      throw error;
    }
  };

  /** 
      Handling owned communities getter
      
      @param {number} limit
      @param {number} page
    **/

  const getOwnedCommunities = async (limit = 10, page = 1) => {
    const params = `?sort=created_at&order=DESC&limit=${limit}&p=${page}`;
    try {
      const response = await app.$axios.get(
        `${process.env.BASE_API}communities/owned${params}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // =========================================
  // Community Setting API Helpers
  // =========================================

  /**
   * @toggleNotification
   */

  const setToggleNotification = async () => {
    try {
      const response = await app.$axios.post(
        process.env.BASE_API +
          "communities/member/" +
          store.state.auth.user.member.id,
        {
          receive_notification:
            store.state.auth.user.member.receive_notification === 0 ? 1 : 0,
        }
      );
      store.commit("auth/SET_MEMBER", response.data.body);
      return response;
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handling 3 Section of Community Sectione except Listing, it caused by difference of API Endpoint of listing
   *
   * @param {*} section
   * @param {*} communityID
   * @param {*} contentPage
   * @param {*} contentLimit
   * @returns Promise<Content>
   */
  const getSectionContent = async (
    section = "questions",
    communityID,
    contentPage = 1,
    contentLimit = 10,
    customParams = ""
  ) => {
    const limit = `&p=${contentPage ?? ""}`;
    const page = `limit=${contentLimit ?? ""}`;

    if (
      section !== "questions" &&
      section !== "member" &&
      section !== "medias" &&
      section !== "listing"
    ) {
      throw new Error("Section invalid");
    }

    try {
      const response = await app.$axios.get(
        process.env.BASE_API +
          "view/communities/" +
          communityID +
          `/${section}` +
          `?${page + limit}` +
          customParams
      );
      return response.data.body;
    } catch (err) {
      throw err;
    }
  };

  class Community {
    constructor() {}
  }

  class Listing {
    /**
     * Handling listing content getter by Community.
     * @param {*} contentLimit
     * @param {*} contentPage
     * @returns Promise<listingContent[]>
     */
    async getListingbyCommunity(id = null, params = {}) {
      if (!id) throw new Error("no ID Community preserved");
      const queryParams = app.$queryGenerator(params);
      try {
        const response = await app.$axios.get(
          process.env.BASE_API +
            `marketplace/listing/community/${id}` +
            queryParams
        );
        return response.data.body;
      } catch (err) {
        throw err;
      }
    }
  }
  /**
   * Handling listing content getter.
   * @param {*} keyword
   * @param {*} category_id
   * @param {*} contentLimit
   * @param {*} contentPage
   * @returns Promise<listingContent[]>
   */
  const getListingContent = async (
    keyword = "",
    category_id = null,
    contentLimit = 10,
    contentPage = 1
  ) => {
    const params = `?limit=${contentLimit}&p=${contentPage}`;
    const addOnParams =
      !keyword && !category_id
        ? ""
        : `&keyword=${keyword}&category_id=${category_id}`;
    try {
      const response = await app.$axios.get(
        process.env.BASE_API + "marketplace/listing" + params + addOnParams
      );
      return response.data.body;
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handling listing content getter by Community.
   * @param {*} contentLimit
   * @param {*} contentPage
   * @returns Promise<listingContent[]>
   */
  const getListingContentbyCommunity = async (
    id = null,
    contentLimit = 10,
    contentPage = 1
  ) => {
    if (!id) throw new Error("no ID Community preserved");
    const params = `?limit=${contentLimit}&p=${contentPage}`;
    try {
      const response = await app.$axios.get(
        process.env.BASE_API + `marketplace/listing/community/${id}` + params
      );
      return response.data.body;
    } catch (err) {
      throw err;
    }
  };

  // =========================================
  // Post Item API Helpers
  // =========================================

  /**
   * @Broadcast Discussion
   */

  const broadcastDiscussion = async (discussionID) => {
    try {
      const response = await app.$axios.post(
        process.env.BASE_API + "communities/questions/broadcast",
        {
          community_content_id: discussionID,
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  };

  /**
   * @pin
   */
  const pinDiscussion = async (discussionID, isPinned) => {
    try {
      const response = await app.$axios.post(
        process.env.BASE_API + `communities/questions/${discussionID}`,
        {
          is_pinned: isPinned ? 0 : 1,
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  };
  /**
   * @delete
   */
  const deleteDiscussion = async (discussionID) => {
    try {
      const response = await app.$axios.delete(
        process.env.BASE_API + `communities/questions/${discussionID}`
      );

      return response;
    } catch (err) {
      throw err;
    }
  };

  // Listing Service Instance
  const listingService = new Listing();

  inject("submitJoinCommunity", submitJoinCommunity);
  inject("getQuestions", getQuestions);
  inject("getSectionContent", getSectionContent);
  inject("getListingContent", getListingContent);
  inject("getListingContentbyCommunity", getListingContentbyCommunity);

  // Discussions injection
  inject("getPopularDiscussions", getPopularDiscussions);

  // Get communities helpers injection
  inject("getCommunities", getCommunities);
  inject("getRecommendedCommunities", getRecommendedCommunities);
  inject("getOwnedCommunities", getOwnedCommunities);

  // Community Setting helpers injection
  inject("setToggleNotification", setToggleNotification);

  // Post Item Helpers injection
  inject("broadcastDiscussion", broadcastDiscussion);
  inject("pinDiscussion", pinDiscussion);
  inject("deleteDiscussion", deleteDiscussion);

  // Listing Service Injection
  inject("listingService", listingService);
};
